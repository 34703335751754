import { useStaticQuery, graphql } from 'gatsby'

function useFormUrl(props) {
    const data = useStaticQuery(graphql`
        query GET_FORM_URL {
            site {
                siteMetadata {
                    hubspotSubmitFormUrl
                }
            }
        }      
    `)
    const hubspotUrl = data.site.siteMetadata.hubspotSubmitFormUrl;
    return hubspotUrl;
}

export default useFormUrl