import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Button from '../../../components/ui/button'
import SectionTitle from '../../../components/ui/section-title'
import {AboutWrapper, LeftBox, RightBox} from './about-area.style'

const AboutDesc = ({sectionTitleStyle, headingStyle, textStyle, descStyle, btnStyle}) => {
    const data = useStaticQuery(graphql`
    {
      globalYaml(id: {eq: "about-us"}) {
        top {
          description
          page_title
        }
        left_graphic {
          description
          heading
          text
        }
      }
    }
  `)
    const {top: {page_title, description}, left_graphic } = data.globalYaml;
    return (
        <AboutWrapper>
            <Container>
                <Row>
                    <Col lg={7} md={9} ml="auto" mr="auto">
                        <SectionTitle
                            {...sectionTitleStyle}
                            title={page_title}
                            subtitle={description}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={{span: 4, offset: 1}}>
                        <LeftBox>
                            {left_graphic.heading && <Heading {...headingStyle}>{parse(left_graphic.heading)}</Heading>}
                            {left_graphic.text && <Heading {...textStyle}>{parse(left_graphic.text)}</Heading>}
                        </LeftBox>
                    </Col>
                    <Col lg={{span: 5, offset: 1}}>
                        <RightBox>
                            {left_graphic.description && <Text {...descStyle}>{parse(left_graphic.description)}</Text>}
                            <Button {...btnStyle} to={`/contact-us`}>Discover More</Button>
                        </RightBox>
                    </Col>
                </Row>
            </Container>
        </AboutWrapper>
    )
}

AboutDesc.propTypes = {
    sectionStyle: PropTypes.object,
    sectionTitleStyle: PropTypes.object,
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object,
    descStyle: PropTypes.object,
}

AboutDesc.defaultProps = {
    sectionTitleStyle: {
        mb: '70px',
        responsive: {
            small: {
                mb: '30px'
            }
        }
    },
    headingStyle: {
        as: "h3",
        layout: "highlight",
        maxwidth: "330px",
        mb: "24px",
        responsive: {
            medium: {
                maxwidth: "100%"
            }
        }
    },
    textStyle: {
        as: "h4",
        fontSize: "20px"
    },
    descStyle: {
        fontSize: "18px",
        lineHeight: 1.67,
        mb: "23px"
    },
    btnStyle: {
        varient: "texted",
        iconname: "far fa-long-arrow-right",
        hover: "false"
    }
}

export default AboutDesc;